import {AlertCircle, CheckCircle, Clock, FileText, Settings, Upload} from 'lucide-react';

export const Dashboard = () => {

    return (
        <div className="min-h-screen bg-gray-50 p-8">
            {/* Header */}
            <div className="mb-8 flex justify-between items-center">
                <div>
                    <h1 className="text-3xl font-bold text-gray-900">CV Optimizer</h1>
                    <p className="text-gray-500">Career Seeker Plan</p>
                </div>
                <div className="flex gap-4">
                    <button
                        className="px-4 py-2 border border-gray-300 rounded-lg flex items-center gap-2 hover:bg-gray-50">
                        <Settings className="w-4 h-4"/>
                        Settings
                    </button>
                    <button
                        className="px-4 py-2 bg-blue-600 text-white rounded-lg flex items-center gap-2 hover:bg-blue-700">
                        <Upload className="w-4 h-4"/>
                        New Optimization
                    </button>
                </div>
            </div>

            {/* Usage Stats */}
            <div className="bg-white p-6 rounded-lg shadow-sm mb-8">
                <h2 className="text-lg font-semibold mb-2">Usage Overview</h2>
                <p className="text-gray-500 mb-4">
                </p>
                <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                        className="bg-blue-600 h-2 rounded-full"
                    />
                </div>
            </div>

            {/* Main Content */}
            <div className="grid md:grid-cols-3 gap-8">
                {/* Recent Activity */}
                <div className="md:col-span-2 bg-white p-6 rounded-lg shadow-sm">
                    <h2 className="text-lg font-semibold mb-6">Recent Activity</h2>
                    <div className="space-y-4">
                        <ActivityItem
                            title="Software Engineer - Google"
                            status="complete"
                            time="2 hours ago"
                            score={92}
                        />
                        <ActivityItem
                            title="Product Manager - Apple"
                            status="processing"
                            time="3 hours ago"
                            score={undefined}
                        />
                        <ActivityItem
                            title="Data Scientist - Meta"
                            status="failed"
                            time="1 day ago"
                            score={undefined}
                        />
                    </div>
                </div>

                {/* Quick Actions */}
                <div className="bg-white p-6 rounded-lg shadow-sm">
                    <h2 className="text-lg font-semibold mb-6">Quick Actions</h2>
                    <div className="space-y-4">
                        <button
                            className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg flex items-center gap-2 hover:bg-blue-700">
                            <Upload className="w-4 h-4"/>
                            Upload New CV
                        </button>
                        <button
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg flex items-center gap-2 hover:bg-gray-50">
                            <FileText className="w-4 h-4"/>
                            Browse Templates
                        </button>
                        <button
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg flex items-center gap-2 hover:bg-gray-50">
                            <Clock className="w-4 h-4"/>
                            View History
                        </button>
                    </div>
                </div>

                {/* Templates */}
                <div className="md:col-span-3 bg-white p-6 rounded-lg shadow-sm">
                    <h2 className="text-lg font-semibold mb-6">Templates</h2>
                    <div className="flex gap-4 mb-6">
                        <button className="px-4 py-2 bg-blue-600 text-white rounded-lg">All Templates</button>
                        <button className="px-4 py-2 text-gray-600 hover:bg-gray-50 rounded-lg">Technology</button>
                        <button className="px-4 py-2 text-gray-600 hover:bg-gray-50 rounded-lg">Business</button>
                        <button className="px-4 py-2 text-gray-600 hover:bg-gray-50 rounded-lg">Creative</button>
                    </div>
                    <div className="grid md:grid-cols-3 gap-4">
                        <TemplateCard title="Modern Professional" uses={1205}/>
                        <TemplateCard title="Technical Expert" uses={892}/>
                        <TemplateCard title="Creative Portfolio" uses={654}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ActivityItem = (props: { title: string, status: string, time: string, score: number | undefined }) => {
    const getStatusIcon = () => {
        switch (props.status) {
            case 'complete':
                return <CheckCircle className="w-5 h-5 text-green-500"/>;
            case 'processing':
                return <Clock className="w-5 h-5 text-blue-500"/>;
            case 'failed':
                return <AlertCircle className="w-5 h-5 text-red-500"/>;
            default:
                return null;
        }
    };

    return (
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-4">
                {getStatusIcon()}
                <div>
                    <h3 className="font-medium">{props.title}</h3>
                    <p className="text-sm text-gray-500">{props.time}</p>
                </div>
            </div>
            {props.score && <div className="text-green-600 font-medium">Score: {props.score}</div>}
        </div>
    );
};

const TemplateCard = (props: { title: string, uses: number }) => (
    <div className="bg-white border border-gray-200 rounded-lg overflow-hidden">
        <div className="p-4">
            <h3 className="text-lg font-semibold">{props.title}</h3>
            <p className="text-gray-500">{props.uses} uses</p>
        </div>
        <div className="p-4 border-t">
            <button className="w-full px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50">
                Use Template
            </button>
        </div>
    </div>
);

