import { useState } from 'react';
import { Check, HelpCircle,} from 'lucide-react';

export const Pricing = () => {
    const [annual, setAnnual] = useState(false);
    const [hoveredPlan, setHoveredPlan] = useState<number | null>(null);

    const plans = [
        {
            name: 'Free',
            description: 'Perfect for trying out CV Optimizer',
            price: 0,
            features: [
                '3 CV optimizations per month',
                'Basic CV analysis',
                'Standard templates',
                'Email support',
                'Basic job matching'
            ],
            limitations: [
                'No AI-powered suggestions',
                'No custom templates',
                'No priority support'
            ]
        },
        {
            name: 'Light',
            description: 'For job seekers who want more power',
            price: annual ? 8.99 : 9.99,
            features: [
                '10 CV optimizations per month',
                'Advanced CV analysis',
                'AI-powered suggestions',
                'Custom templates',
                'Priority email support',
                'Advanced job matching',
                'CV performance analytics',
                'Keyword optimization'
            ]
        },
        {
            name: 'Pro',
            description: 'For professionals seeking the best results',
            price: annual ? 16.99 : 19.99,
            features: [
                'Unlimited CV optimizations',
                'Real-time CV analysis',
                'Advanced AI suggestions',
                'Custom branded templates',
                '24/7 Priority support',
                'Expert job matching',
                'Detailed analytics dashboard',
                'Keyword optimization',
                'Industry insights',
                'API access',
                'Multiple CV versions',
                'Interview preparation tools'
            ]
        }
    ];

    return (
        <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 py-12 px-4">
            <div className="max-w-7xl mx-auto">
                {/* Header Section */}
                <div className="text-center mb-16">
                    <h1 className="text-4xl font-bold text-gray-900 mb-4">
                        Simple, transparent pricing
                    </h1>
                    <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-8">
                        Choose the perfect plan for your job search journey
                    </p>

                    {/* Billing Toggle */}
                    <div className="flex items-center justify-center space-x-4">
            <span className={`text-sm ${!annual ? 'text-gray-900 font-medium' : 'text-gray-500'}`}>
              Monthly billing
            </span>
                        <button
                            onClick={() => setAnnual(!annual)}
                            className="relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-300"
                            style={{ backgroundColor: annual ? '#3B82F6' : '#94A3B8' }}
                        >
              <span
                  className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-300 ${
                      annual ? 'translate-x-6' : 'translate-x-1'
                  }`}
              />
                        </button>
                        <span className={`text-sm ${annual ? 'text-gray-900 font-medium' : 'text-gray-500'}`}>
              Annual billing
              <span className="ml-1.5 inline-flex items-center rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">
                Save 20%
              </span>
            </span>
                    </div>
                </div>

                {/* Plans Grid */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
                    {plans.map((plan, index) => (
                        <div
                            key={plan.name}
                            className={`relative bg-white rounded-2xl shadow-sm transition-all duration-300 ${
                                hoveredPlan === index ? 'transform -translate-y-2 shadow-lg' : ''
                            }`}
                            onMouseEnter={() => setHoveredPlan(index)}
                            onMouseLeave={() => setHoveredPlan(null)}
                        >
                            {plan.name === 'Pro' && (
                                <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                  <span className="bg-gradient-to-r from-blue-600 to-indigo-600 text-white px-4 py-1 rounded-full text-sm font-medium">
                    Most Popular
                  </span>
                                </div>
                            )}

                            <div className="p-8">
                                <div className="mb-6">
                                    <h2 className="text-2xl font-bold text-gray-900 mb-2">{plan.name}</h2>
                                    <p className="text-gray-500">{plan.description}</p>
                                </div>

                                <div className="mb-6">
                                    <div className="flex items-end">
                    <span className="text-4xl font-bold text-gray-900">
                      ${plan.price}
                    </span>
                                        {plan.price > 0 && (
                                            <span className="text-gray-500 ml-2 mb-1">/month</span>
                                        )}
                                    </div>
                                    {plan.price > 0 && annual && (
                                        <p className="text-sm text-green-600 mt-2">
                                            Includes 2 months free
                                        </p>
                                    )}
                                </div>

                                <button
                                    className={`w-full py-3 px-4 rounded-xl font-medium transition-colors duration-200 mb-6 ${
                                        plan.name === 'Pro'
                                            ? 'bg-gradient-to-r from-blue-600 to-indigo-600 text-white hover:from-blue-700 hover:to-indigo-700'
                                            : 'bg-gray-50 text-gray-900 hover:bg-gray-100'
                                    }`}
                                >
                                    {plan.price === 0 ? 'Start Free' : 'Get Started'}
                                </button>

                                <div className="space-y-4">
                                    <p className="text-sm font-medium text-gray-900">Plan includes:</p>
                                    {plan.features.map((feature, fIndex) => (
                                        <div key={fIndex} className="flex items-start space-x-3">
                                            <Check className="w-5 h-5 text-green-500 flex-shrink-0" />
                                            <span className="text-gray-600 text-sm">{feature}</span>
                                        </div>
                                    ))}

                                    {plan.limitations && (
                                        <>
                                            <div className="border-t border-gray-100 my-4" />
                                            <p className="text-sm font-medium text-gray-900">Plan limitations:</p>
                                            {plan.limitations.map((limitation, lIndex) => (
                                                <div key={lIndex} className="flex items-start space-x-3">
                                                    <div className="w-5 h-5 flex items-center justify-center flex-shrink-0">
                                                        <div className="w-1 h-1 bg-gray-300 rounded-full" />
                                                    </div>
                                                    <span className="text-gray-500 text-sm">{limitation}</span>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Feature Comparison */}
                <div className="bg-white rounded-2xl shadow-sm p-8 mb-16">
                    <h2 className="text-2xl font-bold text-gray-900 mb-8 text-center">
                        Detailed Feature Comparison
                    </h2>

                    <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                        <div className="space-y-8">
                            <div className="h-12" /> {/* Header spacer */}
                            <div className="font-medium text-gray-900">Core Features</div>
                            <div>CV Optimizations</div>
                            <div>CV Analysis</div>
                            <div>Templates</div>
                            <div>Support</div>
                            <div>Job Matching</div>
                            <div className="font-medium text-gray-900">Advanced Features</div>
                            <div>AI Suggestions</div>
                            <div>Analytics</div>
                            <div>API Access</div>
                            <div>Interview Tools</div>
                        </div>

                        {['Free', 'Light', 'Pro'].map((plan) => (
                            <div key={plan} className="space-y-8 text-center">
                                <div className="font-bold text-lg text-gray-900">{plan}</div>
                                {[
                                    plan === 'Free' ? '3/month' : plan === 'Light' ? '10/month' : 'Unlimited',
                                    plan === 'Free' ? 'Basic' : plan === 'Light' ? 'Advanced' : 'Real-time',
                                    plan === 'Free' ? 'Standard' : plan === 'Light' ? 'Custom' : 'Branded',
                                    plan === 'Free' ? 'Email' : plan === 'Light' ? 'Priority' : '24/7 Priority',
                                    plan === 'Free' ? 'Basic' : plan === 'Light' ? 'Advanced' : 'Expert',
                                    '', // Spacer for Advanced Features header
                                    plan === 'Free' ? '✕' : '✓',
                                    plan === 'Free' ? '✕' : plan === 'Light' ? 'Basic' : 'Advanced',
                                    plan === 'Free' ? '✕' : plan === 'Light' ? '✕' : '✓',
                                    plan === 'Free' ? '✕' : plan === 'Light' ? '✕' : '✓'
                                ].map((feature, index) => (
                                    <div key={index} className={feature === '✓' ? 'text-green-600' : feature === '✕' ? 'text-red-400' : ''}>
                                        {feature}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>

                {/* FAQ Section */}
                <div className="max-w-3xl mx-auto">
                    <h2 className="text-2xl font-bold text-gray-900 mb-8 text-center">
                        Frequently Asked Questions
                    </h2>

                    <div className="space-y-6">
                        {[
                            {
                                q: "Can I change plans at any time?",
                                a: "Yes, you can upgrade, downgrade, or cancel your plan at any time. Changes take effect at the start of the next billing cycle."
                            },
                            {
                                q: "What payment methods do you accept?",
                                a: "We accept all major credit cards, PayPal, and Apple Pay. All payments are processed securely through Stripe."
                            },
                            {
                                q: "Is there a refund policy?",
                                a: "Yes, we offer a 14-day money-back guarantee if you're not satisfied with your subscription."
                            },
                            {
                                q: "Do unused optimizations roll over?",
                                a: "Monthly optimization credits expire at the end of each billing cycle and do not roll over to the next month."
                            }
                        ].map((faq, index) => (
                            <div key={index} className="bg-gray-50 rounded-xl p-6">
                                <div className="flex items-start space-x-4">
                                    <HelpCircle className="w-6 h-6 text-blue-600 flex-shrink-0" />
                                    <div>
                                        <h3 className="font-medium text-gray-900 mb-2">{faq.q}</h3>
                                        <p className="text-gray-600">{faq.a}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* CTA Section */}
                <div className="mt-16 text-center">
                    <div className="max-w-3xl mx-auto bg-gradient-to-r from-blue-600 to-indigo-600 rounded-2xl p-8 text-white">
                        <h2 className="text-2xl font-bold mb-4">
                            Ready to optimize your job search?
                        </h2>
                        <p className="mb-6 opacity-90">
                            Start with our free plan and upgrade anytime as your needs grow
                        </p>
                        <button className="bg-white text-blue-600 px-6 py-3 rounded-xl font-medium hover:bg-blue-50 transition-colors">
                            Get Started Now
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

