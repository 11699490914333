import html2pdf from 'html2pdf.js';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import TurndownService from 'turndown';

interface ExportOptions {
    filename?: string;
    margins?: {
        top?: number;
        right?: number;
        bottom?: number;
        left?: number;
    };
    pageSize?: {
        width?: number;
        height?: number;
    };
}

/**
 * Convert HTML content to PDF and trigger download
 */
export const exportToPDF = async (
    elementId: string,
    options: ExportOptions = {}
): Promise<void> => {
    const element = document.getElementById(elementId);
    if (!element) {
        throw new Error(`Element with id "${elementId}" not found`);
    }

    const defaultOptions = {
        margin: [0, 0],
        filename: 'document.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
            scale: 1.5,
            useCORS: true,
            letterRendering: true
        },
        jsPDF: {
            unit: 'mm',
            format: 'a4',
            orientation: 'portrait'
        }
    };

    const exportOptions = {
        ...defaultOptions,
        filename: options.filename || defaultOptions.filename,
        margin: [
            options.margins?.top || defaultOptions.margin[0],
            options.margins?.right || defaultOptions.margin[1],
            options.margins?.bottom || defaultOptions.margin[0],
            options.margins?.left || defaultOptions.margin[1]
        ]
    };

    try {
        await html2pdf().from(element).set(exportOptions).save();
        console.log('PDF export completed successfully');
    } catch (error) {
        console.error('Error exporting to PDF:', error);
        throw error;
    }
};

/**
 * Convert HTML to DOCX content structure
 */
const htmlToDocxContent = (htmlString: string): Document => {
    const turndownService = new TurndownService();
    const markdown = turndownService.turndown(htmlString);

    const doc = new Document({
        sections: [{
            properties: {},
            children: markdown.split('\n').map(line => {
                if (line.startsWith('#')) {
                    // Handle headers
                    const level = line.match(/^#+/)?.[0].length || 0;
                    const text = line.replace(/^#+\s/, '');
                    return new Paragraph({
                        text,
                        heading: `Heading${level}` as "Heading1" | "Heading2" | "Heading3" | "Heading4" | "Heading5" | "Heading6" | "Title" | undefined
                    });
                } else if (line.startsWith('*') || line.startsWith('-')) {
                    // Handle bullet points
                    const text = line.replace(/^\*\s|-\s/, '');
                    return new Paragraph({
                        text,
                        bullet: {
                            level: 0
                        }
                    });
                } else {
                    // Handle regular paragraphs
                    return new Paragraph({
                        children: [
                            new TextRun({
                                text: line,
                                break: line === '' ? 1 : 0
                            })
                        ]
                    });
                }
            })
        }]
    });

    return doc;
};

/**
 * Convert HTML content to DOCX and trigger download
 */
export const exportToDocx = async (
    elementId: string,
    options: ExportOptions = {}
): Promise<void> => {
    const element = document.getElementById(elementId);
    if (!element) {
        throw new Error(`Element with id "${elementId}" not found`);
    }

    try {
        // Get HTML content
        const htmlContent = element.innerHTML;

        // Convert HTML to DOCX document
        const doc = htmlToDocxContent(htmlContent);

        // Generate blob
        const blob = await Packer.toBlob(doc);

        // Create download link
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = options.filename || 'document.docx';

        // Trigger download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        console.log('DOCX export completed successfully');
    } catch (error) {
        console.error('Error exporting to DOCX:', error);
        throw error;
    }
};

// Utility function to clean HTML content before export
// const cleanHtmlForExport = (html: string): string => {
//     // Remove scripts
//     html = html.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
//
//     // Remove inline styles
//     html = html.replace(/style="[^"]*"/gi, '');
//
//     // Remove comments
//     html = html.replace(/<!--[\s\S]*?-->/g, '');
//
//     // Remove empty tags
//     html = html.replace(/<(\w+)\s*(?:(?!>)\s)*>\s*<\/\1>/g, '');
//
//     return html.trim();
// };

// Helper function to handle export errors
const handleExportError = (error: Error, format: 'PDF' | 'DOCX'): void => {
    console.error(`Error exporting to ${format}:`, error);
    // You can implement custom error handling here
    throw error;
};

// Combined export function that handles both formats
export const exportDocument = async (
    elementId: string,
    format: 'pdf' | 'docx',
    options: ExportOptions = {}
): Promise<void> => {
    try {
        switch (format.toLowerCase()) {
            case 'pdf':
                await exportToPDF(elementId, options);
                break;
            case 'docx':
                await exportToDocx(elementId, options);
                break;
            default:
                throw new Error(`Unsupported format: ${format}`);
        }
    } catch (error) {
        handleExportError(error as Error, format.toUpperCase() as 'PDF' | 'DOCX');
    }
};
