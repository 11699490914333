import {ChangeEvent, FormEvent, useState} from "react";
import { signInWithPopup, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth, Providers } from "../../config/firebase";
import { Tab } from "@headlessui/react";
import {
    Mail,
    User,
    Loader,
} from "lucide-react";
import Center from "../utils/Center.tsx";

const LoadingState = () => (
    <div className="max-w-2xl mx-auto text-center py-12">
        <div className="w-16 h-16 rounded-full bg-blue-50 flex items-center justify-center mx-auto mb-6">
            <Loader className="w-8 h-8 text-blue-600 animate-spin"/>
        </div>
    </div>
);

const AuthContainer = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(0);
    const [isResetPassword, setIsResetPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        password: "",
    });

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const signInWithGoogle = async () => {
        try {
            await signInWithPopup(auth, Providers.google);
            navigate("/");
        } catch (error) {
            setError(error!.toString());
        } finally {
            setIsLoading(false);
        }
    };

    const handleEmailAuth = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setError("");

        try {
            if (isResetPassword) {
                await sendPasswordResetEmail(auth, formData.email);
                setError("Password reset email sent! Check your inbox.");
                setIsResetPassword(false);
            } else if (activeTab === 0) {
                await signInWithEmailAndPassword(auth, formData.email, formData.password);
                navigate("/")
            } else {
                await createUserWithEmailAndPassword(auth, formData.email, formData.password);
                navigate("/")
            }
        } catch (error) {
            setError(error!.toString());
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <Center><LoadingState /></Center>
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 p-6 flex items-center justify-center">
            <div className="max-w-md w-full">
                <div className="text-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-800 mb-2">
                        {isResetPassword ? "Reset Password" : activeTab === 0 ? "Welcome Back" : "Create Account"}
                    </h1>
                    <p className="text-gray-600">
                        {isResetPassword
                            ? "Enter your email to reset your password"
                            : activeTab === 0
                                ? "Sign in to your account"
                                : "Sign up for a new account"}
                    </p>
                </div>

                <div className="bg-white rounded-2xl shadow-xl p-6">
                    {!isResetPassword && (
                        <Tab.Group selectedIndex={activeTab} onChange={setActiveTab}>
                            <Tab.List className="flex space-x-1 rounded-lg bg-blue-50 p-1 mb-6">
                                <Tab className={({ selected }) =>
                                    `w-full py-2.5 font-medium leading-5 rounded-lg transition-all duration-200 flex items-center justify-center space-x-2
                  ${selected
                                        ? 'bg-white text-blue-600 shadow'
                                        : 'text-gray-600 hover:bg-white/[0.5] hover:text-blue-600'
                                    }`
                                }>
                                    <Mail className="w-4 h-4"/>
                                    <span>Sign In</span>
                                </Tab>
                                <Tab className={({ selected }) =>
                                    `w-full py-2.5 font-medium leading-5 rounded-lg transition-all duration-200 flex items-center justify-center space-x-2
                  ${selected
                                        ? 'bg-white text-blue-600 shadow'
                                        : 'text-gray-600 hover:bg-white/[0.5] hover:text-blue-600'
                                    }`
                                }>
                                    <User className="w-4 h-4"/>
                                    <span>Register</span>
                                </Tab>
                            </Tab.List>

                            <Tab.Panels>
                                <Tab.Panel>
                                    <form onSubmit={handleEmailAuth} className="space-y-4">
                                        <div>
                                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                                                Email Address
                                            </label>
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                required
                                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                                placeholder="your@email.com"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                            />
                                        </div>

                                        <div>
                                            <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                                                Password
                                            </label>
                                            <input
                                                id="password"
                                                name="password"
                                                type="password"
                                                required
                                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                                placeholder="Enter your password"
                                                value={formData.password}
                                                onChange={handleInputChange}
                                            />
                                        </div>

                                        <div className="flex justify-end">
                                            <button
                                                type="button"
                                                onClick={() => setIsResetPassword(true)}
                                                className="text-sm text-blue-600 hover:text-blue-500"
                                            >
                                                Forgot password?
                                            </button>
                                        </div>

                                        <button
                                            type="submit"
                                            disabled={isLoading}
                                            className="w-full py-3 px-4 bg-blue-600 hover:bg-blue-700 disabled:bg-blue-400 disabled:cursor-not-allowed text-white rounded-lg font-medium transition-colors duration-200"
                                        >
                                            Sign In
                                        </button>
                                    </form>
                                </Tab.Panel>

                                <Tab.Panel>
                                    <form onSubmit={handleEmailAuth} className="space-y-4">
                                        <div>
                                            <label htmlFor="fullName" className="block text-sm font-medium text-gray-700 mb-1">
                                                Full Name
                                            </label>
                                            <input
                                                id="fullName"
                                                name="fullName"
                                                type="text"
                                                required
                                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                                placeholder="John Doe"
                                                value={formData.fullName}
                                                onChange={handleInputChange}
                                            />
                                        </div>

                                        <div>
                                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                                                Email Address
                                            </label>
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                required
                                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                                placeholder="your@email.com"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                            />
                                        </div>

                                        <div>
                                            <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                                                Password
                                            </label>
                                            <input
                                                id="password"
                                                name="password"
                                                type="password"
                                                required
                                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                                placeholder="Create a password"
                                                value={formData.password}
                                                onChange={handleInputChange}
                                            />
                                        </div>

                                        <button
                                            type="submit"
                                            disabled={isLoading}
                                            className="w-full py-3 px-4 bg-blue-600 hover:bg-blue-700 disabled:bg-blue-400 disabled:cursor-not-allowed text-white rounded-lg font-medium transition-colors duration-200"
                                        >
                                            Create Account
                                        </button>
                                    </form>
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    )}

                    {isResetPassword && (
                        <form onSubmit={handleEmailAuth} className="space-y-4">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                                    Email Address
                                </label>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    required
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                    placeholder="your@email.com"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    onClick={() => setIsResetPassword(false)}
                                    className="text-sm text-blue-600 hover:text-blue-500"
                                >
                                    Back to sign in
                                </button>
                            </div>

                            <button
                                type="submit"
                                disabled={isLoading}
                                className="w-full py-3 px-4 bg-blue-600 hover:bg-blue-700 disabled:bg-blue-400 disabled:cursor-not-allowed text-white rounded-lg font-medium transition-colors duration-200"
                            >
                                Reset Password
                            </button>
                        </form>
                    )}

                    {error && (
                        <div className="mt-4 p-3 rounded-lg bg-red-50 text-red-600 text-sm">
                            {error}
                        </div>
                    )}

                    {!isResetPassword && (
                        <div className="mt-6">
                            <div className="relative">
                                <div className="absolute inset-0 flex items-center">
                                    <div className="w-full border-t border-gray-300" />
                                </div>
                                <div className="relative flex justify-center text-sm">
                                    <span className="px-2 bg-white text-gray-500">Or continue with</span>
                                </div>
                            </div>

                            <button
                                type="button"
                                onClick={signInWithGoogle}
                                disabled={isLoading}
                                className="mt-4 w-full py-3 px-4 bg-white border border-gray-300 hover:bg-gray-50 disabled:bg-gray-100 disabled:cursor-not-allowed text-gray-700 rounded-lg font-medium transition-colors duration-200 flex items-center justify-center space-x-2"
                            >
                                <svg className="w-5 h-5" viewBox="0 0 24 24">
                                    <path
                                        fill="#4285F4"
                                        d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                                    />
                                    <path
                                        fill="#34A853"
                                        d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                                    />
                                    <path
                                        fill="#FBBC05"
                                        d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                                    />
                                    <path
                                        fill="#EA4335"
                                        d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                                    />
                                </svg>
                                <span>Continue with Google</span>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AuthContainer;
