import { CVData } from '../../hooks/useCvs';


const Resume = ( props: { cv: CVData, id: string}) => {
    const { contactInfo, summary, workExperience, skills, education, languages, certifications, projects } = props.cv;

    return (
        <div className="mx-auto w-[210mm] min-h-[297mm] bg-white shadow-lg p-8 print:shadow-none print:p-0" id={props.id}>
            {/* Header */}
            <header className="mb-8">
                <h1 className="text-3xl font-bold mb-1">{contactInfo.fullName}</h1>
                <h2 className="text-xl text-gray-600 tracking-widest">SOFTWARE ENGINEER</h2>
            </header>

            <div className="flex flex-col md:flex-row gap-8">
                {/* Left Sidebar */}
                <aside className="md:w-1/3">
                    <div className="space-y-6">
                        {/* Details Section */}
                        <section>
                            <h3 className="font-bold text-lg mb-3">Details</h3>
                            <div className="space-y-2 text-sm">
                                <p>{contactInfo.phone}</p>
                                <p>{contactInfo.email}</p>
                                {contactInfo.location && <p>{contactInfo.location}</p>}
                                {contactInfo.linkedIn && (
                                    <a href={contactInfo.linkedIn} className="text-blue-600 hover:underline">
                                        LinkedIn Profile
                                    </a>
                                )}
                                {contactInfo.website && (
                                    <a href={contactInfo.website} className="text-blue-600 hover:underline">
                                        Personal Website
                                    </a>
                                )}
                            </div>
                        </section>

                        {/* Skills Section */}
                        <section>
                            <h3 className="font-bold text-lg mb-3">Skills</h3>
                            <div className="space-y-4 text-sm">
                                <div>
                                    <h4 className="font-semibold mb-2">Technical Skills</h4>
                                    <p>{skills.technical.join(", ")}</p>
                                </div>
                                <div>
                                    <h4 className="font-semibold mb-2">Soft Skills</h4>
                                    <p>{skills.soft.join(", ")}</p>
                                </div>
                            </div>
                        </section>

                        {/* Languages Section */}
                        {languages && languages.length > 0 && (
                            <section>
                                <h3 className="font-bold text-lg mb-3">Languages</h3>
                                <div className="space-y-1 text-sm">
                                    {languages && languages.map((language, index) => (
                                        <p key={index}>{language.language}</p>
                                    ))}
                                </div>
                            </section>
                        )}

                        {/* Certifications Section */}
                        {certifications && (
                            <section>
                                <h3 className="font-bold text-lg mb-3">Certifications</h3>
                                <div className="space-y-3 text-sm">
                                    {certifications.map((cert, index) => (
                                        <div key={index}>
                                            <p className="font-semibold">{cert.name}</p>
                                            <p className="text-gray-600">{cert.issuer}</p>
                                            <p className="text-gray-500">
                                                {new Date(cert.date).toLocaleDateString()}
                                                {cert.expiryDate && ` - ${new Date(cert.expiryDate).toLocaleDateString()}`}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </section>
                        )}
                    </div>
                </aside>

                {/* Main Content */}
                <main className="md:w-2/3">
                    {/* Profile Section */}
                    <section className="mb-8">
                        <h3 className="font-bold text-lg mb-3">Profile</h3>
                        <p className="text-sm leading-relaxed">{summary}</p>
                    </section>

                    {/* Employment History Section */}
                    <section className="mb-8">
                        <h3 className="font-bold text-lg mb-4">Employment History</h3>
                        <div className="space-y-6">
                            {workExperience.map((job, index) => (
                                <div key={index} className="mb-6">
                                    <div className="flex justify-between items-start mb-2">
                                        <div>
                                            <h4 className="font-bold text-sm">
                                                {job.title}, {job.company}
                                                {job.location && `, ${job.location}`}
                                            </h4>
                                        </div>
                                        {job.dates && (
                                            <span className="text-sm text-gray-600">
                        {job.dates}
                      </span>
                                        )}
                                    </div>
                                    <div className="text-sm space-y-2">
                                        <p>{job.responsibilities}</p>
                                        <p className="italic">{job.achievements}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </section>

                    {/* Education Section */}
                    {education && education.length > 0 && (
                        <section className="mb-8">
                            <h3 className="font-bold text-lg mb-4">Education</h3>
                            <div className="space-y-4">
                                {education.map((edu, index) => (
                                    <div key={index} className="text-sm">
                                        <div className="flex justify-between items-start mb-1">
                                            <h4 className="font-bold">{edu.institution}</h4>
                                            {edu.dates && (
                                                <span className="text-gray-600">
                          {new Date(edu.dates.start).getFullYear()} -
                                                    {edu.dates.end === 'Present' ? 'Present' : new Date(edu.dates.end!).getFullYear()}
                        </span>
                                            )}
                                        </div>
                                        <p>{edu.degree}{edu.field && ` in ${edu.field}`}</p>
                                        {edu.gpa && <p>GPA: {edu.gpa}</p>}
                                        {edu.location && <p className="text-gray-600">{edu.location}</p>}
                                    </div>
                                ))}
                            </div>
                        </section>
                    )}

                    {/* Projects Section */}
                    {projects && projects.length > 0 && (
                        <section>
                            <h3 className="font-bold text-lg mb-4">Projects</h3>
                            <div className="space-y-4">
                                {projects.map((project, index) => (
                                    <div key={index} className="text-sm">
                                        <div className="flex justify-between items-start mb-1">
                                            <h4 className="font-bold">{project.name}</h4>
                                            {project.dates && (
                                                <span className="text-gray-600">
                          {new Date(project.dates.start).getFullYear()}
                                                    {project.dates.end && ` - ${project.dates.end === 'Present' ? 'Present' :
                                                        new Date(project.dates.end).getFullYear()}`}
                        </span>
                                            )}
                                        </div>
                                        <p>{project.description}</p>
                                        {project.technologies && (
                                            <p className="text-gray-600 mt-1">
                                                Technologies: {project.technologies.join(', ')}
                                            </p>
                                        )}
                                        {project.link && (
                                            <a href={project.link} className="text-blue-600 hover:underline mt-1 block">
                                                Project Link
                                            </a>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </section>
                    )}
                </main>
            </div>
        </div>
    );
};

export default Resume;
