import { useState } from 'react';
import { formatDate } from 'date-fns';
import {
     CreditCard,
    HelpCircle, AlertTriangle, LogOut,
    Shield, Calendar,
    ChevronRight, Check
} from 'lucide-react';
import {useNavigate} from "react-router-dom";

export const UserProfile = ({ user = {
    createdAt: "2024-10-24T16:56:25.000Z",
    email: "sample@example.com",
    fullName: "Sample User",
    lastLogin: "2024-10-24T16:56:25.000Z",
    plan: "free"
}}) => {
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const navigate = useNavigate();
    const PlanFeatures = {
        free: ['3 CV optimizations/month', 'Basic analytics', 'Email support'],
        light: ['10 CV optimizations/month', 'Advanced analytics', 'Priority support', 'Custom templates'],
        pro: ['Unlimited optimizations', 'Real-time analytics', '24/7 Priority support', 'Custom templates', 'API access']
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 p-6 pt-16">
            <div className="max-w-6xl mx-auto">
                <div className="mb-8">
                    <h1 className="text-3xl font-bold text-gray-800">Account Settings</h1>
                    <p className="text-gray-600">Manage your account settings and preferences</p>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                    {/* Main Profile Section */}
                    <div className="lg:col-span-2 space-y-6">
                        <div className="bg-white rounded-2xl shadow-sm p-6">
                            <div className="flex items-center space-x-4 mb-6">
                                <div className="w-20 h-20 bg-gradient-to-br from-blue-400 to-indigo-500 rounded-full flex items-center justify-center">
                  <span className="text-2xl font-bold text-white">
                    {user.fullName.split(' ').map(n => n[0]).join('')}
                  </span>
                                </div>
                                <div>
                                    <h2 className="text-2xl font-bold text-gray-800">{user.fullName}</h2>
                                    <p className="text-gray-600">{user.email}</p>
                                </div>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div className="space-y-4">
                                    <div className="flex items-center space-x-3 text-gray-600">
                                        <Calendar className="w-5 h-5" />
                                        <span>Joined {formatDate(new Date(user.createdAt), 'MMMM dd, yyyy')}</span>
                                    </div>
                                </div>

                                <div className="space-y-4">
                                    <div className="flex items-center space-x-3 text-gray-600">
                                        <Shield className="w-5 h-5" />
                                        <span className="capitalize">{user.plan} Plan</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Subscription Section */}
                        <div className="bg-white rounded-2xl shadow-sm p-6">
                            <h3 className="text-xl font-bold text-gray-800 mb-6">Your Subscription</h3>
                            <div className="bg-gradient-to-br from-blue-500 to-indigo-600 rounded-xl p-6 text-white mb-6">
                                <div className="flex justify-between items-center mb-4">
                                    <span className="text-lg font-semibold capitalize">{user.plan} Plan</span>
                                    {user.plan !== 'free' && (
                                        <span className="text-sm bg-white/20 px-3 py-1 rounded-full">Active</span>
                                    )}
                                </div>
                                <ul className="space-y-2 mb-6">

                                    {PlanFeatures.free.map((feature, index) => (
                                        <li key={index} className="flex items-center space-x-2">
                                            <Check className="w-4 h-4" />
                                            <span>{feature}</span>
                                        </li>
                                    ))}
                                </ul>
                                {user.plan !== 'pro' && (
                                    <button className="w-full bg-white text-blue-600 py-2 rounded-lg font-medium hover:bg-blue-50 transition-colors" onClick={() => {navigate('../pricing')}}>
                                        Upgrade Now
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Side Panel */}
                    <div className="space-y-6">
                        {/* Quick Actions */}
                        <div className="bg-white rounded-2xl shadow-sm p-6">
                            <h3 className="text-xl font-bold text-gray-800 mb-4">Quick Actions</h3>
                            <div className="space-y-3">
                                {/*<button className="w-full flex items-center space-x-3 p-3 rounded-lg hover:bg-gray-50 transition-colors">*/}
                                {/*    <Settings className="w-5 h-5 text-gray-600" />*/}
                                {/*    <span className="font-medium">Account Settings</span>*/}
                                {/*</button>*/}
                                <button className="w-full flex items-center space-x-3 p-3 rounded-lg hover:bg-gray-50 transition-colors">
                                    <CreditCard className="w-5 h-5 text-gray-600" />
                                    <span className="font-medium">Billing & Invoices</span>
                                </button>
                                <button className="w-full flex items-center space-x-3 p-3 rounded-lg hover:bg-gray-50 transition-colors" onClick={()=>{navigate(`../help`)}}>
                                    <HelpCircle className="w-5 h-5 text-gray-600" />
                                    <span className="font-medium">Help & Support</span>
                                </button>
                                <button className="w-full flex items-center space-x-3 p-3 rounded-lg hover:bg-gray-50 transition-colors text-red-600">
                                    <LogOut className="w-5 h-5" />
                                    <span className="font-medium">Sign Out</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* Security Section */}
                    <div className="bg-white rounded-2xl shadow-sm p-6 w-auto">
                        <h3 className="text-xl font-bold text-gray-800 mb-6">Security</h3>
                        <div className="space-y-4">
                            <button
                                onClick={() => setShowDeleteConfirm(true)}
                                className="w-full flex items-center justify-between p-4 rounded-xl border border-red-200 hover:border-red-500 hover:bg-red-50 transition-all text-red-600"
                            >
                                <div className="flex items-center space-x-3">
                                    <AlertTriangle className="w-5 h-5" />
                                    <span className="font-medium">Delete Account</span>
                                </div>
                                <ChevronRight className="w-5 h-5" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Delete Account Confirmation Modal */}
            {showDeleteConfirm && (
                <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4">
                    <div className="bg-white rounded-2xl p-6 max-w-md w-full">
                        <h3 className="text-xl font-bold text-gray-800 mb-4">Delete Account</h3>
                        <p className="text-gray-600 mb-6">
                            Are you sure you want to delete your account? This action cannot be undone and all your data will be permanently deleted.
                        </p>
                        <div className="flex space-x-4">
                            <button
                                onClick={() => setShowDeleteConfirm(false)}
                                className="flex-1 py-2 px-4 bg-gray-100 text-gray-700 rounded-lg font-medium hover:bg-gray-200 transition-colors"
                            >
                                Cancel
                            </button>
                            <button
                                className="flex-1 py-2 px-4 bg-red-600 text-white rounded-lg font-medium hover:bg-red-700 transition-colors"
                            >
                                Delete Account
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
