import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../config/firebase";
import {LogOut} from "lucide-react";

interface Props {
  navigateTo?: string;
}

const Logout = ({ navigateTo = "/login" }: Props) => {
  const navigate = useNavigate();
  const logout = async () => {
    signOut(auth)
      .then(() => {
        navigate(navigateTo, { replace: true});
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      <button
          className="flex items-center gap-2 text-gray-600 hover:text-gray-900 w-full px-3 py-2 rounded-lg hover:bg-gray-50 transition-colors"
          onClick={logout}
      >
        <LogOut className="w-4 h-4"/>
        <span className="text-sm">Sign Out</span>
      </button>
    </div>
  );
};

export default Logout;
