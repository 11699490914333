import React, {ReactElement, useState} from 'react';
import {
    FileUp,
    History,
    Settings,
    CreditCard,
    FileText,
    Layout,
    HelpCircle,
} from 'lucide-react';
import {useLocation, useNavigate} from "react-router-dom";
import { Menu, X } from 'lucide-react';
import Logout from '../auth/Logout';

const Sidebar = ({ credits = 999, totalCredits = 999 }) => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => setIsOpen(!isOpen);
    return (
        <>
            {/* Mobile Hamburger Button */}
            <button
                onClick={toggleSidebar}
                className="lg:hidden fixed top-4 left-4 z-50 p-2 rounded-lg bg-white shadow-md"
            >
                {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>

            {/* Overlay for mobile */}
            {isOpen && (
                <div
                    className="lg:hidden fixed inset-0 bg-black/50 z-30"
                    onClick={toggleSidebar}
                />
            )}

            {/* Sidebar */}
            <div className={`
                fixed top-0 left-0 z-40
                h-screen w-72 bg-white shadow-sm
                transition-transform duration-300 ease-in-out
                lg:translate-x-0
                ${isOpen ? 'translate-x-0' : '-translate-x-full'}
            `}>
                {/* Logo Section */}
                <div className="px-6 h-16 flex items-center border-b border-gray-100 py-6">
                    <div className="flex items-center gap-2">
                        <div className="w-8 h-8 rounded-lg bg-blue-600 flex items-center justify-center">
                            <span className="text-white font-bold">CV</span>
                        </div>
                        <span className="text-lg font-semibold bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent">
                            CVSync.io
                        </span>
                    </div>
                </div>

                {/* Credits Card */}
                <div className="p-4">
                    <div className="relative overflow-hidden rounded-2xl bg-gradient-to-br from-blue-600 to-blue-400 p-5 text-white">
                        <div className="flex items-center gap-2 mb-2">
                            <CreditCard className="w-4 h-3" />
                            <span className="text-sm font-medium opacity-90">Available Credits</span>
                        </div>

                        <div className="mb-4">
                            <div className="text-3xl font-bold mb-1">{credits}</div>
                            <div className="text-sm opacity-80">of {totalCredits} credits</div>
                        </div>

                        <div className="w-full bg-white/20 rounded-full h-1.5">
                            <div
                                className="bg-white h-1.5 rounded-full"
                                style={{ width: `${(credits/totalCredits) * 100}%` }}
                            />
                        </div>

                        <div className="absolute -right-8 -bottom-8 w-32 h-32 rounded-full border border-white/20" />
                        <div className="absolute -right-6 -bottom-6 w-24 h-24 rounded-full border border-white/20" />
                    </div>
                </div>

                {/* Navigation */}
                <div className="flex-1 px-4 py-2 space-y-1">
                    <div className="mb-6">
                        <div className="px-4 py-2 text-xs font-semibold text-gray-400 uppercase tracking-wider">
                            Main
                        </div>
                        <NavButton
                            icon={<FileUp />}
                            text="Optimize CV"
                            href={""}
                            active={location.pathname === '/'}
                            badge="New"
                            onClick={() => setIsOpen(false)}
                        />
                        <NavButton
                            icon={<History />}
                            text="Optimization History"
                            href={'optimizations'}
                            active={location.pathname === '/optimizations'}
                            badge={""}
                            onClick={() => setIsOpen(false)}
                        />
                        <NavButton
                            icon={<FileText />}
                            href={'my-cvs'}
                            active={location.pathname === '/my-cvs'}
                            text="My CVs"
                            onClick={() => setIsOpen(false)}
                        />
                    </div>

                    <div className="mb-6">
                        <div className="px-4 py-2 text-xs font-semibold text-gray-400 uppercase tracking-wider">
                            Resources
                        </div>
                        <NavButton
                            icon={<Layout />}
                            text="Template Gallery"
                            href={`./`}
                            active={location.pathname === '/templates'}
                            badge="Soon"
                            onClick={() => setIsOpen(false)}
                        />
                    </div>

                    <div>
                        <div className="px-4 py-2 text-xs font-semibold text-gray-400 uppercase tracking-wider">
                            Account
                        </div>
                        <NavButton
                            icon={<Settings />}
                            href={'settings'}
                            active={location.pathname === '/settings'}
                            text="Settings"
                            onClick={() => setIsOpen(false)}
                        />
                        <NavButton
                            icon={<HelpCircle />}
                            href={'help'}
                            active={location.pathname === '/help'}
                            text="Help Center"
                            onClick={() => setIsOpen(false)}
                        />
                    </div>
                </div>

                {/* Bottom Section */}
                <div className="p-4 mt-auto">
                    <Logout />
                </div>
            </div>
        </>
    );
};

interface NavButtonProps {
    icon: ReactElement;
    text: string;
    href: string;
    active?: boolean;
    badge?: string | number;
    onClick?: () => void;
}

const NavButton = ({ icon, text, active = false, badge, href, onClick }: NavButtonProps) => {
    const navigate = useNavigate();
    return (
        <button
            onClick={() => {
                onClick?.();
                navigate(`/${href}`);
            }}
            className={`
        group relative flex items-center w-full px-4 py-2.5 mb-1
        rounded-xl transition-all duration-200
        ${active
                ? 'bg-blue-50 text-blue-600'
                : 'text-gray-600 hover:bg-gray-50'
            }
      `}
        >
            {/* Icon */}
            <div className={`
        flex items-center justify-center
        transition-all duration-200
        ${active ? 'text-blue-600' : 'text-gray-400 group-hover:text-gray-600'}
      `}>
                {React.cloneElement(icon, { className: 'w-[18px] h-[18px]' })}
            </div>

            {/* Text */}
            <span className={`
        ml-3 text-sm font-medium transition-colors duration-200
        ${active ? 'text-blue-600' : 'text-gray-600 group-hover:text-gray-900'}
      `}>
        {text}
      </span>

            {/* Badge */}
            {badge && (
                <span className={`
          ml-auto text-xs px-2 py-0.5 rounded-full
          ${typeof badge === 'string' && badge.toLowerCase() === 'pro'
                    ? 'bg-gradient-to-r from-amber-400 to-amber-500 text-white'
                    : active
                        ? 'bg-blue-100 text-blue-600'
                        : 'bg-gray-100 text-gray-600'
                }
        `}>
          {badge}
        </span>
            )}
        </button>
    );
};

export default Sidebar;
