import {useState} from 'react'
import {Calendar, Mail, Phone} from 'lucide-react';
import {
    Certification,
    ContactInfo,
    CVData,
    DateRange,
    DateRangeString,
    DateString,
    Education,
    Project,
    Skills,
    useCvs,
    WorkExperience
} from "../../hooks/useCvs";
import Template1 from "../templates/Template1";
// Utility types for handling partial data
type PartialWorkExperience = Partial<WorkExperience>;
type PartialEducation = Partial<Education>;
type PartialProject = Partial<Project>;

// Type guards for checking data completeness
const isCompleteWorkExperience = (work: PartialWorkExperience): work is WorkExperience => {
    return !!(work.title && work.company);
};

const isCompleteEducation = (education: PartialEducation): education is Education => {
    return !!(education.institution && education.degree);
};

// Helper function to parse date range string
const parseDateRange = (dateRange: DateRangeString): DateRange => {
    const [start, end] = dateRange.split('/');
    return {start, end};
};

// Helper function to format date range for display
const formatDateRange = (dateRange: DateRange): string => {
    const endDate = dateRange.end === 'Present' ? 'Present' :
        dateRange.end ? new Date(dateRange.end).toLocaleDateString() : '';
    return `${new Date(dateRange.start).toLocaleDateString()} - ${endDate}`;
};

export type {
    DateString,
    DateRange,
    DateRangeString,
    ContactInfo,
    WorkExperience,
    Skills,
    Certification,
    Education,
    Project,
    PartialWorkExperience,
    PartialEducation,
    PartialProject,
};

export {
    isCompleteWorkExperience,
    isCompleteEducation,
    parseDateRange,
    formatDateRange,
};

export const CVList = () => {
    const {cvs} = useCvs();
    const [selectedCV, setSelectedCV] = useState<CVData | null>(null);
    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        }).format(date);
    };

    const getRandomGradient = () => {
        const gradients = [
            'from-blue-500 to-purple-500',
            'from-green-500 to-teal-500',
            'from-orange-500 to-pink-500',
            'from-indigo-500 to-blue-500',
            'from-pink-500 to-rose-500',
        ];
        return gradients[Math.floor(Math.random() * gradients.length)];
    };

    return (selectedCV ? (
                <Template1 cv={selectedCV} id={"cv-content"}/>
            ) :
            <div className="p-24 bg-gray-50 min-h-screen ">
                <div className="max-w-7xl mx-auto">
                    <h1 className="text-3xl font-bold mb-8 text-gray-800">Your CV Collection</h1>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {cvs?.map((cv) => {
                            const gradientClass = getRandomGradient();

                            return (
                                <div key={cv.createdAt}
                                     className="bg-white rounded-lg shadow-lg overflow-hidden transform transition-all duration-200 hover:scale-105">
                                    <div className={`h-3 bg-gradient-to-r ${gradientClass}`}/>

                                    <div className="p-6">
                                        <div className="flex items-start justify-between">
                                            <div>
                                                <h2 className="text-xl font-semibold text-gray-800">
                                                    {cv.contactInfo?.fullName || 'Untitled CV'}
                                                </h2>
                                                <p className="text-sm text-gray-500 mt-1">
                                                    <Calendar className="inline-block w-4 h-4 mr-1"/>
                                                    {formatDate(cv.createdAt)}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="mt-4 space-y-2">
                                            {cv.contactInfo?.email && (
                                                <p className="text-sm text-gray-600 flex items-center">
                                                    <Mail className="w-4 h-4 mr-2"/>
                                                    {cv.contactInfo.email}
                                                </p>
                                            )}
                                            {cv.contactInfo?.phone && (
                                                <p className="text-sm text-gray-600 flex items-center">
                                                    <Phone className="w-4 h-4 mr-2"/>
                                                    {cv.contactInfo?.phone}
                                                </p>
                                            )}
                                        </div>

                                        {(cv.skills?.technical?.length ?? 0) > 0 && (
                                            <div className="mt-4">
                                                <p className="text-sm font-medium text-gray-700 mb-2">Technical
                                                    Skills</p>
                                                <div className="flex flex-wrap gap-2">
                                                    {cv.skills?.technical.slice(0, 3).map((skill, idx) => (
                                                        <span
                                                            key={idx}
                                                            className="px-2 py-1 text-xs rounded-full bg-gray-100 text-gray-600"
                                                        >
                            {skill}
                          </span>
                                                    ))}
                                                    {(cv.skills?.technical?.length ?? 0) > 3 && (
                                                        <span
                                                            className="px-2 py-1 text-xs rounded-full bg-gray-100 text-gray-600">
                            +{(cv.skills?.technical?.length ?? 0) - 3} more
                          </span>
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                        {(cv.languages?.length ?? 0) > 0 && (
                                            <div className="mt-4">
                                                <p className="text-sm font-medium text-gray-700 mb-2">Languages</p>
                                                <div className="flex flex-wrap gap-2">
                                                    {cv.languages!.map((language, idx) => (
                                                        <span
                                                            key={idx}
                                                            className="px-2 py-1 text-xs rounded-full bg-blue-100 text-blue-600"
                                                        >
                            {language.language}
                          </span>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="px-6 py-3 bg-gray-50 flex justify-end">
                                        <button className="text-sm text-blue-600 hover:text-blue-800 font-medium"
                                                onClick={() => {
                                                    setSelectedCV(cv)
                                                }}>
                                            View CV
                                        </button>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
    );
};
