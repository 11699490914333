import React from 'react';
import {exportDocument} from './export-utils';
import {Download} from 'lucide-react';
import {CVData} from "../../hooks/useCvs";
import Template1 from "../templates/Template1";
import { ArrowLeft } from "lucide-react";

interface ExportButtonProps {
    cvId: string;
    format: 'pdf' | 'docx';
    className?: string;
}

const ExportButton: React.FC<ExportButtonProps> = ({cvId, format, className}) => {
    const handleExport = async () => {
        try {
            await exportDocument(cvId, format, {
                filename: `cv-export.${format}`,
                margins: {top: 0, right: 0, bottom: 0, left: 0 }
            });
        } catch (error) {
            console.error(`Error exporting to ${format.toUpperCase()}:`, error);
            // Handle error (e.g., show notification to user)
        }
    };

    return (
        <button
            onClick={handleExport}
            className={`flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${className}`}
        >
            <Download className="w-4 h-4"/>
            Export {format.toUpperCase()}
        </button>
    );
};

// Example usage in CV component
const CVWithExport = (props: { cv: CVData, onBack: () => void }) => {
    return (
        <>
            <ArrowLeft
                className="cursor-pointer hover:text-gray-600"
                onClick={props.onBack}
            />
        <div className={"flex flex-row-reverse gap-8 align-center justify-end"}>
            <div className="flex flex-col gap-8">
                <div className="flex flex-row gap-4 items-center">

                        <div className="py-2 z-50 flex flex-row gap-2 ml-auto">
                            <ExportButton cvId="cv-content" format="pdf" className="p-12"/>
                            <ExportButton cvId="cv-content" format="docx"/>
                        </div>

                </div>
                <div className="bg-white rounded-lg shadow-lg flex-row">
                    <Template1 cv={props.cv} id="cv-content"/>
                </div>
            </div>
        </div>
    </>
    )
};

export default CVWithExport;
