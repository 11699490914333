import AuthContainer from "../components/auth/AuthContainer";


const Login = () => {
    return (
        <AuthContainer/>
    );
};


export default Login;
