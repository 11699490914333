import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import {CVData} from "./useCvs";
interface OptimizationDetails {
    changes: string[];
    expectedMatchPercentage: number;
    rationale: string[];
}

interface OriginalAnalysis {
    matchPercentage: number;
    matchingSkills: string[];
    missingSkills: string[];
}

export interface Optimization {
    id: string;
    jobUrl?: string;
    createdAt?: string;
    optimizedCV: CVData;
    status?: 'completed' | 'failed' | 'in-progress';
    originalAnalysis?: OriginalAnalysis;
    userId?: string;
    optimizationDetails?: OptimizationDetails;
    jobTitle?: string;
    company?: string;
    optimizedCvUrl?: string;
}

interface UseOptimizationsResult {
    optimizations: Optimization[] | undefined;
    loading: boolean;
    error: string | null;
    refetch: () => Promise<void>;
}


export const useOptimizations = (): UseOptimizationsResult => {
    const [optimizations, setOptimizations] = useState<Optimization[] | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [user, setUser] = useState<User | null>(null);
    const auth = getAuth();

    const fetchOptimizations = async () => {
        if (!user) return;
        setLoading(true);
        try {
            const idToken = await user.getIdToken();
            const response = await fetch(`${import.meta.env.VITE_APP_API_URL}/optimization/`, {
                headers: {
                    'Authorization': `Bearer ${idToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setOptimizations(data.sort((a: Optimization, b: Optimization) => { return new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime() }));
            setError(null);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Failed to fetch optimization data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });

        return () => unsubscribe();
    }, [auth]);

    useEffect(() => {
        fetchOptimizations();

        // Set up auto-refresh every 10 seconds
        // const intervalId = setInterval(fetchOptimizations, 10000);

        // return () => clearInterval(intervalId);
    }, [user]);

    return { optimizations, loading, error, refetch: fetchOptimizations };
};
