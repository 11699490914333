import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import posthog from 'posthog-js'

posthog.init('phc_LAKFQ1WPtVsb3nEPuNs0lBqAEDnBbglZsfoRRtESHZO',
    {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'identified_only'
    }
)
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
