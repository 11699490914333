import  { useState } from 'react';
import { Download, ExternalLink } from 'lucide-react';
import { Optimization, useOptimizations } from "../../hooks/useOptimizations";
import ExportButton from "./ExportButton";


export const Optimizations = () => {
    return <OptimizationView />
}


const OptimizationView = () => {
    const [selectedOptimization, setSelectedOptimization] = useState<Optimization | null>(null);
    const [activeView, setActiveView] = useState<'table' | 'detail' >('table');
    const [activeTab, setActiveTab] = useState('overview');
    const { optimizations, loading, error } = useOptimizations();

    const formatDate = (dateString: string) => {
        return new Date(dateString).toLocaleDateString();
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center h-64">
                <div className="text-lg text-gray-500">Loading optimizations...</div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-64">
                <div className="text-lg text-red-500">{error}</div>
            </div>
        );
    }

    const renderOptimizationTable = () => (
        <div className="bg-white rounded-lg shadow">
            <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
                <h2 className="text-xl font-semibold text-gray-900">CV Optimizations</h2>
            </div>

            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Job Title
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Match %
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Expected %
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Status
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Date
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Actions
                        </th>
                    </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {optimizations && optimizations.map((opt, index) => (
                        <tr key={index} className="hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm font-medium text-gray-900">
                                    {opt.jobTitle || opt.id}
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                                <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
                                    {opt.originalAnalysis?.matchPercentage}%
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                                <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                                    {opt.optimizationDetails?.expectedMatchPercentage}%
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                        opt.status === 'completed' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                                    }`}>
                                        {opt.status}
                                    </span>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {formatDate(opt.createdAt!)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                <div className="flex space-x-3">
                                    <button
                                        className="inline-flex items-center text-blue-600 hover:text-blue-900"
                                        onClick={() => {
                                            setSelectedOptimization(opt);
                                            setActiveView('detail');
                                        }}
                                    >
                                        <Download className="w-4 h-4 mr-1" />
                                        Export
                                    </button>
                                    <a
                                        href={opt.jobUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="inline-flex items-center text-gray-600 hover:text-gray-900"
                                    >
                                        <ExternalLink className="w-4 h-4 mr-1" />
                                        View Job
                                    </a>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );

    const renderDetailView = () => {
        if (!selectedOptimization) return null;

        return (
            <div className="bg-white rounded-lg shadow">
                {/* Header */}
                <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
                    <h2 className="text-xl font-semibold text-gray-900">Optimization Details</h2>
                    <button
                        onClick={() => setActiveView('table')}
                        className="px-4 py-2 text-sm font-medium text-blue-600 hover:text-blue-800"
                    >
                        Back to Table
                    </button>
                </div>

                {/* Tabs */}
                <div className="border-b border-gray-200">
                    <nav className="flex px-6" aria-label="Tabs">
                        <button
                            onClick={() => setActiveTab('overview')}
                            className={`${
                                activeTab === 'overview'
                                    ? 'border-blue-500 text-blue-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                            } py-4 px-1 border-b-2 font-medium text-sm mr-8`}
                        >
                            Overview
                        </button>
                        <button
                            onClick={() => setActiveTab('skills')}
                            className={`${
                                activeTab === 'skills'
                                    ? 'border-blue-500 text-blue-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                            } py-4 px-1 border-b-2 font-medium text-sm mr-8`}
                        >
                            Skills Analysis
                        </button>
                        <button
                            onClick={() => setActiveTab('changes')}
                            className={`${
                                activeTab === 'changes'
                                    ? 'border-blue-500 text-blue-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                            } py-4 px-1 border-b-2 font-medium text-sm`}
                        >
                            Changes
                        </button>
                    </nav>
                </div>

                {/* Content */}
                <div className="p-6">
                    {activeTab === 'overview' && (
                        <div className="space-y-6">
                            <div className="grid grid-cols-2 gap-4">
                                <div className="p-4 bg-gray-50 rounded-lg">
                                    <div className="text-sm text-gray-500">Original Match</div>
                                    <div className="text-2xl font-bold text-gray-900">
                                        {selectedOptimization.originalAnalysis?.matchPercentage}%
                                    </div>
                                </div>
                                <div className="p-4 bg-gray-50 rounded-lg">
                                    <div className="text-sm text-gray-500">Expected Match</div>
                                    <div className="text-2xl font-bold text-gray-900">
                                        {selectedOptimization.optimizationDetails?.expectedMatchPercentage}%
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h3 className="text-lg font-medium mb-2">Optimization Rationale</h3>
                                <ul className="list-disc pl-5 space-y-2">
                                    {selectedOptimization.optimizationDetails?.rationale.map((reason, index) => (
                                        <li key={index} className="text-gray-700">{reason}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}

                    {activeTab === 'skills' && (
                        <div className="space-y-6">
                            <div>
                                <h3 className="text-lg font-medium mb-3">Matching Skills</h3>
                                <div className="grid grid-cols-2 gap-2">
                                    {selectedOptimization.originalAnalysis?.matchingSkills.map((skill, index) => (
                                        <div key={index} className="flex items-center p-2 bg-green-50 rounded">
                                            <div className="w-2 h-2 bg-green-500 rounded-full mr-2" />
                                            <span className="text-sm">{skill}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div>
                                <h3 className="text-lg font-medium mb-3">Missing Skills</h3>
                                <div className="grid grid-cols-2 gap-2">
                                    {selectedOptimization.originalAnalysis?.missingSkills.map((skill, index) => (
                                        <div key={index} className="flex items-center p-2 bg-red-50 rounded">
                                            <div className="w-2 h-2 bg-red-500 rounded-full mr-2" />
                                            <span className="text-sm">{skill}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    {activeTab === 'changes' && (
                        <div>
                            <h3 className="text-lg font-medium mb-3">Applied Changes</h3>
                            <div className="space-y-3">
                                {selectedOptimization.optimizationDetails?.changes.map((change, index) => (
                                    <div key={index} className="p-3 bg-blue-50 rounded">
                                        <div className="flex items-center">
                                            <div className="w-6 h-6 rounded-full bg-blue-100 flex items-center justify-center mr-3">
                                                <span className="text-sm font-medium text-blue-600">{index + 1}</span>
                                            </div>
                                            <span className="text-gray-700">{change}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="container mx-auto px-4 py-6">
            {activeView === 'table' ? renderOptimizationTable() :
                <div className={"flex flex-row-reverse"}>
                {renderDetailView()}
                <ExportButton cv={selectedOptimization!.optimizedCV} onBack={() => {
                setActiveView('table')
            }}
                />
                </div>
            }
        </div>
    );
};

